// ** Reducers Imports
import { api, apiExample } from '@src/features/api';
import auth from '../features/authentication/AuthenticationSlice';
import navbar from '../features/navbar/navbar';
import layout from '../features/layout/layout';
import business from '../features/business-details/components/BusinessInformationSlice';
import register from '../features/register/RegisterSlice';
import reports from '../features/reports/ReportsSlice';

const rootReducer = {
  auth,
  navbar,
  layout,
  business,
  register,
  reports,
  [api.reducerPath]: api.reducer,
  [apiExample.reducerPath]: apiExample.reducer,
};

export default rootReducer;
