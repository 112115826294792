import basicIcon from '@src/assets/images/illustration/Pot1.svg';
import standardIcon from '@src/assets/images/illustration/Pot2.svg';
import enterpriseIcon from '@src/assets/images/illustration/Pot3.svg';

export interface IPlan {
  planId: number;
  planName: string;
  title: string;
  img: string;
  subtitle: string;
  planBenefits: string[];
  popular: boolean;
}

export const BLANK_PLAN: IPlan = {
  planId: 0,
  planName: '',
  title: '',
  img: '',
  subtitle: '',
  planBenefits: [],
  popular: false,
};

export interface IRegisterAccount {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export const BLANK_REGISTER_ACCOUNT: IRegisterAccount = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

export interface IRegisterBusiness {
  name?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: number | '';
}

export const BLANK_REGISTER_BUSINESS: IRegisterBusiness = {
  name: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
};

export enum RegistrationStatus {
  Idle,
  Pending,
  Submitted,
  Completed,
  Error,
}

export const mockPlanData: IPlan[] = [
  {
    planId: 1,
    planName: 'basic',
    title: 'Basic',
    img: basicIcon,
    subtitle: 'A simple start for everyone',
    planBenefits: ['FREE forever', 'Up to 3 daily bookings'],
    popular: false,
  },
  {
    planId: 2,
    planName: 'standard',
    title: 'Standard',
    img: standardIcon,
    subtitle: 'Great for small businesses',
    planBenefits: ['10% booking fee', 'Up to 10 daily bookings'],
    popular: true,
  },
  {
    planId: 3,
    planName: 'enterprise',
    title: 'Enterprise',
    img: enterpriseIcon,
    subtitle: 'Ideal for large businesses',
    planBenefits: ['15% booking fee', 'Unlimited bookings'],
    popular: false,
  },
];
