export enum DateRangeType {
  Today = 'Today',
  Yesterday = 'Yesterday',
  ThisWeek = 'This Week',
  ThisMonth = 'This Month',
  ThisYear = 'This Year',
  LastWeek = 'Last Week',
  LastMonth = 'Last Month',
  LastYear = 'Last Year',
  Custom = 'Custom',
}

export enum CompensationValueType {
  Percentage = 'Percentage',
  Fixed = 'Fixed',
  Undefined = 'Undefined',
}

export interface IDateRange {
  endDate: string;
  startDate: string;
}

export interface IPayrollSummaryDataResponse {
  therapistId: string;
  firstName: string;
  lastName: string;
  minutesWorked: number;
  numberOfServices: number;
  noShowEarnings: number;
  checkedOutEarnings: number;
  addOnEarnings: number;
  tips: number;
  treatmentCompensation: number;
  addOnCompensation: number;
}

export interface ITherapistPayrollDataResponse {
  therapistId: string;
  firstName: string;
  lastName: string;
  payrollAppointments: IAppointmentPayrollDataResponse[];
}

export interface IAppointmentPayrollDataResponse {
  appointmentId: string;
  bookingId: string;
  durationInMinutes: number;
  appointmentStatus: string;
  groupSize: number;
  orderId: string;
  orderState: string;
  treatmentPrice: number;
  tip: number;
  addOnTotal: number;
  totalPaid: number;
  total: number;
  starts: string;
  treatmentCompensation: number;
  addOnCompensation: number;
  totalCompensation: number;
  compensationValueType: CompensationValueType;
  compensationValue: number;
}

export interface IAppointmentPayrollData {
  date: string;
  time: string;
  duration: number;
  status: string;
  type: string;
  servicePrice: string;
  addOnTotal: string;
  tip: string;
  serviceCompensation: string;
  addOnCompensation: string;
  totalCompensation: string;
}
