// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { store } from './app/store';

// ** Intl & ThemeColors Context
import { ThemeContext } from './utility/context/ThemeColors';
import { AbilityContext } from './utility/context/Can';
import ability from './configs/acl/ability';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';
import { ai, getAppInsights } from './app/appInsights';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <LazyApp />
          <ToastContainer newestOnTop />
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

try {
  ai.initialize();
  const appInsights = getAppInsights();

  if (!appInsights) {
    throw Error('AppInsights not initialized');
  }

  appInsights.trackEvent({ name: 'Test Exception Event' });
} catch (error) {
  // eslint-disable-next-line no-console
  console.log('AI Error: ', error);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
