import { createSlice } from '@reduxjs/toolkit';
import { BLANK_BUSINESS, IBusiness } from '../types';

interface BusinessState {
  business: IBusiness;
  businessHours: [];
}

const initialState: BusinessState = {
  business: BLANK_BUSINESS,
  businessHours: [],
};

export const BusinessInformationSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setLogo: (
      state: BusinessState,
      action: { payload: string; type: string }
    ) => {
      state.business.logo = action.payload;
    },
  },
  extraReducers: () => {},
});

export const { setLogo } = BusinessInformationSlice.actions;

export default BusinessInformationSlice.reducer;
