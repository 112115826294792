export enum Role {
  Owner = 'owner',
  Manager = 'manager',
  Therapist = 'therapist',
  Receptionist = 'receptionist',
  Admin = 'admin',
}

export type RoleType = {
  name: Role;
};

export type OptionType = {
  value: string;
  label: string;
};

export interface IOption {
  value: string | number;
  label: string;
}

export const roleOptions: IOption[] = [
  { value: Role.Therapist, label: 'Therapist' },
  { value: Role.Receptionist, label: 'Receptionist' },
  { value: Role.Manager, label: 'Manager' },
];

export type RequestError = {
  status: number;
  data?: {
    reason?: string;
  };
};
