import jwt from '@src/auth/jwt/useJwt';
import refreshTokens from '@src/features/authentication/TokenRefresh';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import appConfig from '@src/configs/appConfig';

export type AxiosClientAttributes = {
  baseUrl?: string;
  correlationId?: string;
  useAuthToken?: boolean;
};

const { jwtConfig } = jwt;

export class AxiosClient {
  client: AxiosInstance;

  baseUrl: string;

  correlationId: string | undefined;

  constructor({
    baseUrl = appConfig.urls.baseUrl,
    correlationId,
    useAuthToken = true,
  }: AxiosClientAttributes) {
    this.baseUrl = baseUrl;
    this.correlationId = correlationId;
    this.client = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      timeout: 10_000,
    });

    axiosRetry(this.client, {
      retries: 3,
      retryDelay: (retryCount) => retryCount * 1000,
      retryCondition: (error) => error.response?.status.toString()[0] === '5',
    });

    if (useAuthToken) {
      this.setupInterceptors();
    }
  }

  getRequest(url: string) {
    return this.client.get(url).then((response) => response);
  }

  postRequest(
    url: string,
    payload: any,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return this.client
      .post(url, payload, config ?? undefined)
      .then((response) => response);
  }

  putRequest(url: string, payload: any) {
    return this.client.put(url, payload).then((response) => response);
  }

  patchRequest(url: string, payload: any) {
    return this.client.patch(url, payload).then((response) => response);
  }

  deleteRequest(url: string) {
    return this.client.delete(url).then((response) => response);
  }

  setupInterceptors() {
    this.client.interceptors.request.use(async (request) => {
      const currentDate = Date.now();
      const expiresAt = parseInt(
        localStorage.getItem(jwtConfig.storageTokenExpiresAtKeyName) ?? '',
        10
      );
      if (expiresAt && expiresAt < currentDate + 30_000) {
        await refreshTokens();
      }
      if (request.headers) {
        request.headers['X-Correlation-ID'] = this.correlationId ?? '';
        const token = localStorage.getItem(jwtConfig.storageTokenKeyName);
        if (token) {
          request.headers.Authorization = `${
            jwtConfig.tokenType
          } ${token.replace(/['"]+/g, '')}`;
        }
      }
      return request;
    });

    this.client.interceptors.response.use(
      async (response) => response,
      async (err) => {
        const originalRequest = err.config;

        if (
          err.response.status === 401 ||
          (err.response.status === 403 &&
            originalRequest.url !== '/login' &&
            !originalRequest.retry)
        ) {
          originalRequest.retry = true;
          return refreshTokens().then((tokenObject) => {
            originalRequest.headers.Authorization = `${
              jwtConfig.tokenType
            } ${tokenObject?.access_token.replace(/['"]+/g, '')}`;
            return this.client(originalRequest);
          });
        }

        return Promise.reject(err);
      }
    );
  }
}
