let environment = 'dev'
if (process.env.REACT_APP_BUILD_ENV) {
    environment = process.env.REACT_APP_BUILD_ENV
}

// eslint-disable-next-line import/no-dynamic-require
const environmentConfiguration = require(`./appConfig.${environment}`)

const appConfig = {
    welcomeMessage: "Hello from PROD",
    urls: {
        baseUrl: "https://api.spaccurate.com",
        availability_byLocation: "/availability/:locationId",
        availability: "/availability",
        schedule: "/schedule",
        SQUARE_APPLICATION_ID: "",
        SQUARE_LOCATION_ID: ""
    },
    INSTRUMENTATION_KEY: "",
    environment: process.env.REACT_APP_BUILD_ENV,
    ...environmentConfiguration.default
}

export default appConfig
