
const configuration = {
    welcomeMessage: "Hello from DEV",
    urls: {
        baseUrl: "https://dev-api.spaccurate.com",
        IDENTITY_SERVER: "https://dev-iam.spaccurate.com",
        IDENTITY_SERVER_AUTHORIZE_ENDPOINT: "/connect/authorize",
        IDENTITY_SERVER_TOKEN_ENDPOINT: "/connect/token",
        IDENTITY_SERVER_LOGOUT_ENDPOINT: "/connect/endsession",
        availability: "/availability",
        SQUARE_APPLICATION_ID: "sandbox-sq0idb-6kVBkaeRS4_e1GfzcRIj8g",
        SQUARE_LOCATION_ID: "LPJVFA1HS2EBC"
    },
    INSTRUMENTATION_KEY: "35435f43-237b-423a-b582-18b0994d6678"
}

export default configuration
