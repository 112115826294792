import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from '@src/app/store';
import { Role } from '@src/app/types';
import RegisterApi from './RegisterApi';
import {
  BLANK_REGISTER_ACCOUNT,
  BLANK_REGISTER_BUSINESS,
  BLANK_PLAN,
  IRegisterAccount,
  IRegisterBusiness,
  IPlan,
  mockPlanData,
  RegistrationStatus,
} from './types';

type AccountThunkTypes = {
  state: { register: RegisterState };
  dispatch: AppDispatch;
};

type IRegistrationResult = {
  account: IRegisterAccount;
  accountId: string;
  businessConflict?: boolean;
};

export const submitRegistration = createAsyncThunk<
  IRegistrationResult,
  void,
  AccountThunkTypes
>('register/submitRegistration', async (_, { getState, rejectWithValue }) => {
  const {
    account,
    businessInfo,
    businessAlreadyExistsFailure,
    createdAccountID,
  } = getState().register;
  const api = new RegisterApi({});

  const roles = [];
  roles.push(Role.Owner);

  let accountId = '';
  const result: IRegistrationResult = {
    account,
    accountId: '',
    businessConflict: undefined,
  };

  if (!businessAlreadyExistsFailure) {
    try {
      accountId = await api.registerAccount(
        account.firstName,
        account.lastName,
        account.email,
        account.password,
        roles
      );
      result.accountId = accountId;
    } catch (error: any) {
      return rejectWithValue({ response: error.response.data });
    }
  } else {
    accountId = createdAccountID;
  }
  if (businessInfo.name) {
    try {
      await api.registerBusiness(
        businessInfo.name,
        accountId,
        businessInfo.address ?? '',
        businessInfo.city ?? '',
        businessInfo.zipcode?.toString() ?? '',
        businessInfo.state ?? ''
      );
      result.businessConflict = true;
    } catch (error: any) {
      const payload = error.response.data.payload as HttpErrorPayload;
      if (payload?.code === 'invalid_business_name') {
        result.businessConflict = false;
      } else
        return rejectWithValue({
          response: error.response.data,
          accountId,
        });
    }
  }

  return result;
});

interface RegisterState {
  account: IRegisterAccount;
  businessInfo: IRegisterBusiness;
  selectedPlan: IPlan;
  status: RegistrationStatus;
  isRegisteringAsOwner: boolean;
  accountAlreadyExistsFailure?: boolean;
  businessAlreadyExistsFailure?: boolean;
  createdAccountID: string;
}

const initialState: RegisterState = {
  account: BLANK_REGISTER_ACCOUNT,
  businessInfo: BLANK_REGISTER_BUSINESS,
  selectedPlan: BLANK_PLAN,
  status: RegistrationStatus.Pending,
  isRegisteringAsOwner: true,
  createdAccountID: '',
};

type HttpErrorPayload = { code: string; reason: string };

export const RegisterSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    selectPlan: (
      state: RegisterState,
      action: { payload: IPlan; type: string }
    ) => {
      state.selectedPlan = action.payload;
    },
    selectPlanById: (
      state: RegisterState,
      action: { payload: number; type: string }
    ) => {
      const plan = mockPlanData.find((s) => s.planId === action.payload);
      if (!plan) return;
      state.selectedPlan = plan;
    },
    addAccount: (
      state: RegisterState,
      action: { payload: IRegisterAccount; type: string }
    ) => {
      state.account = action.payload;
    },
    addBusiness: (
      state: RegisterState,
      action: { payload: IRegisterBusiness; type: string }
    ) => {
      state.businessInfo = action.payload;
    },
    resetRegistration: (state: RegisterState) => {
      state.account = BLANK_REGISTER_ACCOUNT;
      state.businessInfo = BLANK_REGISTER_BUSINESS;
      state.selectedPlan = BLANK_PLAN;
      state.status = RegistrationStatus.Idle;
      state.isRegisteringAsOwner = true;
      state.accountAlreadyExistsFailure = undefined;
      state.businessAlreadyExistsFailure = undefined;
      state.createdAccountID = '';
    },
    resetFailures: (state: RegisterState) => {
      state.accountAlreadyExistsFailure = undefined;
      // state.businessAlreadyExistsFailure = undefined;
    },
    setRegisterAsOwner: (
      state: RegisterState,
      action: { payload: boolean; type: string }
    ) => {
      state.isRegisteringAsOwner = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitRegistration.pending, (state) => {
        state.status = RegistrationStatus.Submitted;
      })
      .addCase(submitRegistration.fulfilled, (state, action) => {
        state.account = action.payload.account;
        state.businessAlreadyExistsFailure = action.payload.businessConflict;
        state.status = RegistrationStatus.Completed;
      })
      .addCase(submitRegistration.rejected, (state, error) => {
        state.status = RegistrationStatus.Error;
        const payload = error.payload as {
          response: HttpErrorPayload;
          accountId: string;
        };
        if (payload.response.code?.includes('resource_already_exists')) {
          state.accountAlreadyExistsFailure = true;
        } else if (payload.response.code?.includes('invalid_business_name')) {
          state.createdAccountID = payload.accountId;
          state.businessAlreadyExistsFailure = true;
        }
      });
  },
});

export const {
  selectPlan,
  selectPlanById,
  addAccount,
  addBusiness,
  resetRegistration,
  resetFailures,
  setRegisterAsOwner,
} = RegisterSlice.actions;

export default RegisterSlice.reducer;
