export enum TimeZone {
  // Windows - remove after transitioning to IANA
  'Hawaiian Standard Time' = 'Hawaiian Standard Time',
  'Alaskan Standard Time' = 'Alaskan Standard Time',
  'Pacific Standard Time' = 'Pacific Standard Time',
  'Mountain Standard Time' = 'Mountain Standard Time',
  'US Mountain Standard Time' = 'US Mountain Standard Time',
  'Central Standard Time' = 'Central Standard Time',
  'Eastern Standard Time' = 'Eastern Standard Time',
  // IANA
  'America/Anchorage' = 'America/Anchorage',
  'America/Chicago' = 'America/Chicago',
  'America/Denver' = 'America/Denver',
  'America/Los_Angeles' = 'America/Los_Angeles',
  'America/New_York' = 'America/New_York',
  'America/Phoenix' = 'America/Phoenix',
  'Pacific/Honolulu' = 'Pacific/Honolulu',
}

export enum Duration {
  '60-Minute' = '60-Minute',
  '90-Minute' = '90-Minute',
  '120-Minute' = '120-Minute',
}

export interface ILocation {
  id: string;
  businessId: string;
  isActive: boolean;
  name: string;
  address: string;
  phoneNumber: string;
  email: string;
  hoursOfOperations?: IWorkDay[];
  timeZone: TimeZone;
}

export interface ILocationForm {
  id: string;
  businessId: string;
  isActive: boolean;
  name: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressZipCode: string;
  phoneNumber: string;
  email: string;
  timeZone: TimeZone;
}

export interface IOperationExceptions {
  id: string;
  operationExceptions: string[];
}

export interface IOperationException {
  date: string;
}

export interface ILocationHoursForm {
  id: string;
  hoursOfOperations: IWorkDay[];
}

export interface ILocationHours {
  timeZone: TimeZone;
  hoursOfOperations: IWorkDay[];
}

export const BLANK_HOURS_OF_OPERATIONS: IWorkDay[] = [
  {
    dayOfWeek: 'Monday',
    disabled: true,
    openingTime: '07:00 AM',
    closingTime: '09:00 PM',
  },
  {
    dayOfWeek: 'Tuesday',
    disabled: true,
    openingTime: '07:00 AM',
    closingTime: '09:00 PM',
  },
  {
    dayOfWeek: 'Wednesday',
    disabled: true,
    openingTime: '07:00 AM',
    closingTime: '09:00 PM',
  },
  {
    dayOfWeek: 'Thursday',
    disabled: true,
    openingTime: '07:00 AM',
    closingTime: '09:00 PM',
  },
  {
    dayOfWeek: 'Friday',
    disabled: true,
    openingTime: '07:00 AM',
    closingTime: '09:00 PM',
  },
  {
    dayOfWeek: 'Saturday',
    disabled: true,
    openingTime: '07:00 AM',
    closingTime: '09:00 PM',
  },
  {
    dayOfWeek: 'Sunday',
    disabled: true,
    openingTime: '07:00 AM',
    closingTime: '09:00 PM',
  },
];

export const BLANK_LOCATION: ILocation = {
  id: '',
  businessId: '',
  isActive: false,
  name: '',
  address: '',
  phoneNumber: '',
  email: '',
  timeZone: TimeZone['America/New_York'],
};

export const BLANK_LOCATION_HOURS_FORM: ILocationHoursForm = {
  id: '',
  hoursOfOperations: BLANK_HOURS_OF_OPERATIONS,
};

export const BLANK_LOCATION_FORM: ILocationForm = {
  id: '',
  businessId: '',
  isActive: false,
  name: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: '',
  addressZipCode: '',
  phoneNumber: '',
  email: '',
  timeZone: TimeZone['America/New_York'],
};

export const BLANK_ROOM: IRoom = {
  locationId: '',
  id: '',
  roomNumber: '',
  capacity: null,
};

export const BLANK_RATE: IRate = {
  id: '',
  locationId: '',
  duration: 60,
  price: 0,
};

export const BLANK_RATE_FORM: IRateForm = {
  id: '',
  locationId: '',
  duration: Duration['60-Minute'],
  price: 0,
};

export interface IWorkDay {
  disabled: boolean;
  dayOfWeek: string;
  openingTime?: string | null;
  closingTime?: string | null;
}

export interface IWorkDayConfig {
  margin?: number | 120;
  padding?: number | 120;
  endTime?: string | '07:00 AM';
  startTime?: string | '09:00 PM';
  toolTip?: boolean;
  step?: number | 30;
}

export interface IRoom {
  locationId: string;
  id?: string;
  roomNumber?: string;
  capacity?: number | null;
}

export interface IOption {
  value: string | number;
  label: string;
}

export interface ICheckoutDevice {
  id: string;
  squareDeviceId: string;
  squareDeviceCode: string;
  deviceName: string;
}

export interface IAddCheckoutDeviceRequest {
  locationId: string;
  deviceName: string;
}

export interface IRateForm {
  id: string;
  locationId: string;
  duration: Duration;
  price: number | '';
}

export interface IRate {
  id: string;
  locationId: string;
  duration: number;
  price: number;
}

export interface IAddRateRequest {
  locationId: string;
  duration: number;
  price: number;
}

export interface IStripeConnectedAccount {
  accountId: string;
  accountCreated: boolean;
  statementDescriptor: string;
  onboardingComplete: boolean;
}
