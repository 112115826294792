// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const apiConfiguration = {
    loginEndpoint: '/jwt/login',
    registerEndpoint: '/jwt/register',
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',
    responseTokenKeyName: 'access_token'
}

const { jwt } = useJwt(apiConfiguration)

export default jwt
