
const configuration = {
  welcomeMessage: "Hello from QA",
  urls: {
    baseUrl: "https://api.spaccurate.com",
    IDENTITY_SERVER: "https://qa-iam.spaccurate.com",
    IDENTITY_SERVER_AUTHORIZE_ENDPOINT: "/connect/authorize",
    IDENTITY_SERVER_TOKEN_ENDPOINT: "/connect/token",
    IDENTITY_SERVER_LOGOUT_ENDPOINT: "/connect/endsession",
    availability: "/availability"
  }
}

export default configuration
