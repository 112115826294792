import { DateRangeType, IDateRange } from './types';

const GetDateRange = (dateRangeFilter: DateRangeType): IDateRange => {
  const today = new Date(new Date().toDateString());
  const oneDayInMs = 24 * 60 * 60 * 1000;
  let result: IDateRange = { startDate: '', endDate: '' };

  const todaysDayOfWeek = today.getDay();
  let daysFromLastMonday = todaysDayOfWeek - 1;
  if (todaysDayOfWeek === 0) {
    daysFromLastMonday = 6;
  }

  switch (dateRangeFilter) {
    case DateRangeType.Today:
      result = {
        startDate: today.toLocaleDateString(),
        endDate: new Date(today.getTime() + oneDayInMs).toLocaleDateString(),
      };
      break;
    case DateRangeType.Yesterday:
      result = {
        startDate: new Date(today.getTime() - oneDayInMs).toLocaleDateString(),
        endDate: today.toLocaleDateString(),
      };
      break;
    case DateRangeType.ThisWeek:
      result = {
        startDate: new Date(
          today.getTime() - oneDayInMs * daysFromLastMonday
        ).toLocaleDateString(),
        endDate: new Date(
          today.getTime() - oneDayInMs * daysFromLastMonday + oneDayInMs * 7
        ).toLocaleDateString(),
      };
      break;
    case DateRangeType.ThisMonth:
      result = {
        startDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ).toLocaleDateString(),
        endDate: new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          1
        ).toLocaleDateString(),
      };
      break;
    case DateRangeType.ThisYear:
      result = {
        startDate: new Date(today.getFullYear(), 0, 1).toLocaleDateString(),
        endDate: new Date(today.getFullYear() + 1, 0, 1).toLocaleDateString(),
      };
      break;
    case DateRangeType.LastWeek:
      result = {
        startDate: new Date(
          today.getTime() - oneDayInMs * daysFromLastMonday - oneDayInMs * 7
        ).toLocaleDateString(),
        endDate: new Date(
          today.getTime() - oneDayInMs * daysFromLastMonday
        ).toLocaleDateString(),
      };
      break;
    case DateRangeType.LastMonth:
      result = {
        startDate: new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).toLocaleDateString(),
        endDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ).toLocaleDateString(),
      };
      break;
    case DateRangeType.LastYear:
      result = {
        startDate: new Date(today.getFullYear() - 1, 0, 1).toLocaleDateString(),
        endDate: new Date(today.getFullYear(), 0, 1).toLocaleDateString(),
      };
      break;
    default:
      break;
  }
  return result;
};

export default GetDateRange;
