import { Role, RoleType } from '@src/app/types';
import { AxiosClient, AxiosClientAttributes } from '../../app/AxiosClient';
import {
  IAccountRegistration,
  IBusinessLocationData,
  IBusinessPlanRegistration,
  IBusinessRegistration,
} from './RegisterApiTypes';

export default class RegisterApi extends AxiosClient {
  constructor(attributes: AxiosClientAttributes) {
    super({ ...attributes, useAuthToken: false });
  }

  async registerAccount(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    roles: Role[]
  ): Promise<string> {
    const rolesList: RoleType[] = [];
    roles.forEach((role) => {
      rolesList.push({ name: role });
    });

    const account: IAccountRegistration = {
      firstName,
      lastName,
      email,
      password,
      roles: rolesList,
    };

    const response = await this.postRequest('/provider/account', account);
    return response.headers.location.replace(/account\//, '');
  }

  async registerBusiness(
    name: string,
    ownerId: string,
    address: string,
    city: string,
    zipcode: string,
    state: string
  ): Promise<string> {
    const location: IBusinessLocationData = {
      address,
      city,
      zipcode,
      state,
    };
    const business: IBusinessRegistration = { name, ownerId, location };

    const response = await this.postRequest('business', business);
    return response.headers.location.replace(/business\//, '');
  }

  async selectBusinessPlan(
    businessId: string,
    planName: string
  ): Promise<void> {
    const plan: IBusinessPlanRegistration = { planName };

    await this.putRequest(`business/${businessId}/plan`, plan);
  }
}
