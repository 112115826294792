import { TimeZone } from '@src/features/locations/types';
import { randomBytes, createHash } from 'crypto';
import moment from 'moment';
import { Theme } from 'react-select';

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj: Object) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num: number) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html: string) =>
  html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
// const isToday = (date: Date) => {
//   const today = new Date();
//   return (
//     date.getDate() === today.getDate() &&
//     date.getMonth() === today.getMonth() &&
//     date.getFullYear() === today.getFullYear()
//   );
// };

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
// export const formatDate = (
//   value: Date,
//   formatting = { month: 'short', day: 'numeric', year: 'numeric' }
// ) => {
//   if (!value) return value;
//   return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
// };

// ** Returns short month of passed date
// export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
//   const date = new Date(value);
//   let formatting = { month: 'short', day: 'numeric' };

//   if (toTimeForCurrentDay && isToday(date)) {
//     formatting = { hour: 'numeric', minute: 'numeric' };
//   }

//   return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
// };

// ** React Select Theme Colors
export const selectThemeColors = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#3F62B0a', // for option hover bg-color
    primary: '#3F62B0', // for selected option bg-color
    neutral10: '#3F62B0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
});

export const base64URLEncode = (buffer: Buffer) =>
  buffer
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

export const sha256 = (buffer: Buffer) =>
  createHash('sha256').update(buffer).digest();

export const generatePkceVerifier = () => base64URLEncode(randomBytes(64));

export const generatePkceChallenge = (verifier: string) =>
  base64URLEncode(sha256(Buffer.from(verifier)));

export const getDayIndex = (day: string) => {
  switch (day) {
    case 'monday': {
      return 0;
    }
    case 'tuesday': {
      return 1;
    }
    case 'wednesday': {
      return 2;
    }
    case 'thursday': {
      return 3;
    }
    case 'friday': {
      return 4;
    }
    case 'saturday': {
      return 5;
    }
    case 'sunday': {
      return 6;
    }
    default: {
      return '-1';
    }
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

const timeFormat = 'hh:mm A';

export const ConvertToTime = (value: any) => {
  const roundedValue = Math.round(value);

  const time = moment()
    .startOf('day')
    .add(roundedValue, 'minutes')
    .format(timeFormat);
  return time;
};

export const ConvertFromTime = (value: string) => {
  const time = moment(value, timeFormat);
  const minutes = time.hour() * 60 + time.minute();
  return minutes;
};

export const TimeZoneToIANAId = (timeZone: TimeZone | undefined) => {
  switch (timeZone) {
    case TimeZone['Alaskan Standard Time']:
      return 'America/Anchorage';
    case TimeZone['US Mountain Standard Time']:
      return 'America/Phoenix';
    case TimeZone['Central Standard Time']:
      return 'America/Chicago';
    case TimeZone['Eastern Standard Time']:
      return 'America/New_York';
    case TimeZone['Hawaiian Standard Time']:
      return 'Pacific/Honolulu';
    case TimeZone['Mountain Standard Time']:
      return 'America/Denver';
    case TimeZone['Pacific Standard Time']:
      return 'America/Los_Angeles';
    default:
      return timeZone?.toString() ?? '';
  }
};

export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const ConvertDateToSpecificTimeZoneString = (
  date: Date,
  timeZone: TimeZone | undefined,
  style?: 'dateTime' | 'date' | 'time' | undefined
) => {
  if (!timeZone) return '';

  if (style === 'dateTime') {
    return date
      .toLocaleDateString('en-Us', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: TimeZoneToIANAId(timeZone),
      })
      .replaceAll(',', '');
  }

  if (style === 'date') {
    return date
      .toLocaleDateString('en-Us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        timeZone: TimeZoneToIANAId(timeZone),
      })
      .replaceAll(',', '');
  }

  if (style === 'time') {
    return date.toLocaleTimeString('en-Us', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: TimeZoneToIANAId(timeZone),
    });
  }

  return date.toLocaleDateString('en-US', {
    timeZone: TimeZoneToIANAId(timeZone),
  });
};

export const isValidDate = (date: Date) => {
  return date instanceof Date && !Number.isNaN(date.getTime());
};

/* essentially removes the timezone offset of a date. */
/* (ie. 12am eastern would be 5am UTC. This function would return 12am UTC ) */
export const getISODateInUtcIgnoringTimezone = (date: Date) => {
  if (!isValidDate(date)) return '';

  const offset = date.getTimezoneOffset();
  const offsetDate = new Date(date.getTime() - offset * 60000);
  return offsetDate.toISOString();
};

/* offsets date based on difference between local machine timezone and provided timezone */
/* [local machine offset] - [input timezone offset] = [offset to apply to date] */
/* [PST (offset -8)]  -  [EST (offset -5)] = -3 => returns provided date -3 hours */
export const ConvertDateToSpecificTimeZoneDate = (
  date: Date,
  timeZone: TimeZone | undefined
) => {
  if (!timeZone) return date;

  const oldTimeOffset = date.getTimezoneOffset();

  const convertedDateString = date
    .toLocaleDateString('en-Us', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: TimeZoneToIANAId(timeZone),
    })
    .replaceAll(',', '');

  const dateIgnoringTimezone = getISODateInUtcIgnoringTimezone(
    new Date(convertedDateString)
  );

  const timezoneOffsetInMs =
    date.getTime() - new Date(dateIgnoringTimezone).getTime();
  const offset = timezoneOffsetInMs / 60000 - oldTimeOffset;

  const convertedDate = new Date(date.getTime() + offset * 60000);

  return convertedDate;
};
